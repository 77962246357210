
.statusIcon {
	width: 18px;
	height: 18px;
	display: inline-block;
	background: url('../images/published_sprite.png') no-repeat;
	&.unpublished {
		background-position: 0 0;
	}
	&.published {
		background-position: -18px 0;
	}
}

.productListingCell {
	&.published {
		position: absolute;
		top: 0px;
		right: 0;
		width: 24px;
		height: 22px;
		background-color: #fff;
		-webkit-border-radius: 18px;
		-moz-border-radius: 18px;
		border-radius: 18px;
		background-position: -16px;
	}
	&.unpublished {
		position: absolute;
		top: 0px;
		right: 0;
		width: 24px;
		height: 22px;
		background-color: #fff;
		-webkit-border-radius: 18px;
		-moz-border-radius: 18px;
		border-radius: 18px;
		background-position: 3px 1px;
	}
}

#projectTypeBlock {
	label {
		text-align: left;
	}
}

.horizontalTable {
	margin: 20px 0;
}

input[type=number].userInput.PercentInput {
	width: 80%;
}

.editLinkContainer {
	margin-top: 28px;
}

.sidebar {
	color: #fff;
	padding: 20px 20px 40px;
	text-transform: lowercase;
	.col {
		margin: 25px 0 10px;
		&:first-child {
			margin: 10px 0;
		}
	}
	h2 {
		font-size: 1.3em;
		font-weight: 700;
		margin: 0 0 10px;
		color: color(cmsSidebarHeaderColour);
	}

	a {
		color: color(cmsSidebarLinkColour);
		text-decoration: none;
		font-size: 0.9em;
		&:hover {
			color: color(cmsSidebarLinkColour) !important;
			text-decoration: underline;
		}
	}
	.width-half {
		width: 100%;
	}

	.localNav > a {
		float: left;
		width: 100%;
		margin-bottom: 10px;
	}
}


.cmsContainer {
	height: 100%;
	#main {
		background-color: color(cmsSidebarBgColour);
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
			color: color(linkHoverColour);
		}
	}
	@media (max-width: 860px){
		#main {
			> .col {
				width: 100%;
			}
		}

		.logoutLink.right {
			width: 100%;
			padding: 10px 20px;
		}

		.sidebar {
			height: auto;
			min-height: auto !important;
			padding: 20px;
			.width-half {
				width: 50%;
				margin: 0;
			}
		}
	}
}

@media only screen and (max-width: 810px){
	#login_form {
		.col.width-half {
			margin-left: 0;
			width: 100%;
		}
	}
}

.makeUpdatesContainer {
	padding: 20px;
    background-color: #D8D8D8;
	h3 {
		text-transform: uppercase;
		font-size: 1em;
		font-weight: 700;
		color: #5b7074;
		margin: 0;
	}
	p {
		float: left;
		margin: 10px 0 0 0;
		font-size: 12px;
	}
	.alignRight {
		float: right;
	}

	.col:first-child {
		margin-right: 10px;
	}

	@media (max-width: 765px){
		.width-twoThirds {
			width: 100%;
		}
		.width-half {
			width: 100%;
			margin-left: 0;
		}
		.row {
			padding: 0;
		}
		h3 {
			margin-top: 20px;
		}
	}
}

.updateBtn {
	padding: 10px 20px;
    background-color: #4E8ABE;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    float: left;
    margin: 25px 0 20px 0;
    &:hover {
		background-color: #3BA4FF;
    }
}

.typeContainer {
	header {
		@extend %clear;
	}
	ul {
		margin: 10px 0;
		padding: 0;
		list-style: none;
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.linkEdit {
	float: left;
	margin: 0;
	font-size: 13px;
	cursor: pointer;
	clear: both;
}

.linkDuplicate {
	float: left;
	margin: 0;
	font-size: 13px;
	cursor: pointer;
	clear: both;
}

.linkDelete {
	float: left;
	margin: 0;
	font-size: 13px;
	cursor: pointer;
	clear: both;
}

.cmsLoginContainer {
	.mainProdGrid {
		width: 100%;
	}
	@media (max-width: 810px){
		.has-margin .width-half {
			width: 47%;
			margin: 2%;
		}
	}
}

.cmsLoginContainer, .cmsAddProductContainer {
	header {
		margin-top: 30px;
		//margin-bottom: 20px;
		h3 {
			margin: 0 0 10px 0;
			font-size: 1.3em;

		}
		h2 {
			margin: 0;
		}
	}
}

.returnToListing {
	float: right;
}

#main > section.cmsAddProductContainer {
	> header {
		width: 100%;
		h3 {
			float: left;
		}
		h2 {
			float: left;
			clear: left;
		}
	}
}

.topBlock {
	h2{
		font-size: 1.4em;
		float: left;
	}
	a {
		font-size: 1em;
		float: left;
		clear: left;
		margin: 21px 0 0 0;
	}
	header{
		padding-bottom: 10px;
	}
}

.itemForm {
	clear: both;
}

.resultsBlock .publishGroup {
	float: right;
	p {
		font-weight: 100;
		float: none;
		margin: 21px 0 0 0;
	}
	a.btn {
		margin: 0;
		float: left;
		font-weight: bold;
	}
	@media only screen and (max-width: 460px){
		input {
			width: 100% !important;
			clear: both;
		}
	}
}

.publish-btn {
    width: 47%;
    min-width: 159px;
}

.productInformation {
	margin-bottom: 30px;
	label {
		margin-bottom: 14px;
	}
	img {
		margin-bottom: 10px;
	}
	.width-threeQuarters {
		padding-left: 30px;
		@media only screen and (max-width: 760px){
			padding-left: 0;
		}
	}
}


input[type="file"]{
	font-size: 10px;
}

.cmsAddProductContainer header.editSectionHeader {
	margin-bottom: 0;
	@extend %clear;
}

header a.addNew {
	font-weight: bold;
	padding: 5px 10px;
	margin-right: 0;
	margin-top: -8px;
}

.colHeader {
	background-color: #CACACA;
	font-size: 12px;
	> th {
		padding: 10px;
	}
}

.editLinkContainer {
	font-size: 12px;
	width: 100%;
	a {
		display: block;
		margin-bottom: 4px;
	}
}

#category-item-submit {
	width: 47%;
    min-width: 100px;
    margin: 10px 0 !important;
}

.addContainer {
	display: none;
	margin-bottom: 10px;
	background-color: #B5B5B5;
	padding: 20px;
	input[type="button"].btn, .btn {
		margin-left: 20px;
		padding: 10px 50px 10px 30px;
		background-position: top 11px right 28px;
	}
	input[type="text"] {
		font-weight: 100;
	}
	input[type=text],
	input[type=email],
	input[type=phone],
	input[type=password],
	input[type=number],
	input[type=search],
	textarea {
		width: 95%;
	}
	a {
		color: #fff;
	}
	@media only screen and (max-width: 545px){
		form .fullWidth .width-half, form .fullWidth .percentage-width-fifth  {
			width: 100%;
		}
	}

	&.active {
		display: block;
	}
	&.addDialog.active {
		display: table-row;
	}
}

.addContainer input[type="button"].btn, .addContainer input[type="submit"].btn, .addContainer .btn {
	margin-left: 20px;
	padding: 10px 50px 10px 30px;
	background-position: top 11px right 28px;
}

.listItemEdit {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		a {
			margin-left: 15px;
		}
	}
}

.textAlignLeft {
	text-align: left;
}

.textAlignRight {
	text-align: right;
}

.textAlignCenter {
	text-align: center;
}

.listItemEditCell  {
	padding: 5px;
}

.borderThis {
	border: 1px solid #B5B5B5;
	background-color: #fff;
}

.detailsContainer .row.pieceCell {
	padding: 10px;
	margin: 10px 0;
    background-color: #C5C5C5;
}

.addPiece {
	float: right;
	margin: 0;
}

input[type="file"] {
	margin-bottom: 10px;
}

.patternDesign {
	span {
		font-weight: 100;
	}
}

.table-reflow {
	width: 100%;
	table {
		width: 100%;
	}
}

@media only screen and (max-width: 600px) {
  
  .table-reflow {
  	display: block;
    
    thead { display: none; }
    
    caption,
    tbody,
    tfoot,
    tr,
    th,
    td { display: block; width: 100% !important; }
    th {
    	text-align: center;
    	width: 100% !important;
    }
    td:before { content: attr(data-header); text-align: left; width: 50%; display: inline-block; }
    .addDialog td:before, .addedOptions:before {
    	display: none;
    }
    td span {
	    text-align: right;
		width: 48%;
		display: inline-block;
	}
	th.listItemEditCell  {
		background-color: #4788b9;
		color: #fff;
	}
	.listItemEditCell {
		background-color: #fff;
		border-top: 1px solid #4788b9;

	}

	.patternInfo {
		&:before {
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		span {
			width: 100%;
		}
	}

  }
  .has-margin .width-quarter {width: 100%;}

  .itemBorder .width-quarter {
  	width: 22% !important;
  }
}

tr.addDialog, .addDialog {
	display: none;
}
.addDialog {
	font-weight: 700;
	td {
		display: table-cell;
		width: 100%;
		padding: 0px;
	}
	> td {
		padding: 20px;
	}
}

.cancel {
	font-weight: 300;
}

.calculationText {
	margin-top: 11px;
}

.addContainer input[type="text"].calculate_formula {
	margin-top: 1px;
}

.legend {
	font-weight: 300;
	margin: 0;
}

.calcType {
	&:before {
		display: none !important;
	}
}

input[type="number"].percentInput {
	width: 70%;
}

select {
	width: 100%;
	margin-top: 6px;
	padding: 8px;
}

.prodSize {
	padding: 10px;
	margin: 20px 0 0 0;
	font-size: 24px;
	width: 100%;
	background-color: #4788b9;
	color: white;
}

.editInstructionList {
	margin: 0 0 0 21px;
	padding: 0;
	li {
		margin-right: 50px;
		a {
			float: none;
		}
	}
}

.cmsContainer label {
	margin-top: 20px;
	font-weight: 700;
}

.imageUploadContainer {
	margin: 20px 0;
}

.fullForm {
	clear: both;
	float: left;
	margin-top: 30px;
}

.cmsCopyContainer {
	.topBlock a {
		float: none;
	}
}

.cmsCopyBlock {
	a.active {
		text-decoration: underline;
		color: #6C6D70;
	}
}

.optionsDialogContainer {
    float: left;
}

a {
	color: color(accentColor);
}

.sidebar, .calculatorItem {
	.addDialog {
		margin-top: 6px;
		margin-bottom: 10px;
		padding: 10px 10px 8px 10px;
	    background-color: #F1F1F1;
	    float: left;
	    clear: both;
	    width: 100%;
	    label {
	    	font-size: 13px;
	    	color: #4788B9;
	    }
	    
	}
	.btn {
	    padding: 9px 10px 9px 17px;
	    font-size: 12px;
	}
	input[type="button"].btn {
		padding: 9px 32px 9px 20px;
		background-position: right 16px center;
	}

	.optionsButton {
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background-color: #ABCBE2;
		border: none;
		margin-bottom: 5px;
		clear: both;
		float: left;
	}

}

.sidebar input[type="button"].btn, .calculatorItem input[type="button"].btn,.sidebar input[type="submit"].btn, .calculatorItem input[type="submit"].btn {
	padding: 9px 32px 9px 20px;
	background-position: right 16px center;
}


.calculatorItem {

	.delete {
		font-size: 12px;
		font-weight: 400;
		width: 14%;
		text-align: right;
		float: right;
		margin-top: 5px;
	}
	p {
		//width: 70%;
		float: left;
		margin: 0;
	}
}

.cancelBtn {
	margin-left: 10px;
}

.cmsLoginContainer .has-margin .width-half.typeContainer {
	@media (max-width: 650px){
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
}

.sectionHeader {
	margin: 60px 0 0 0;
	font-size: 2em;
    width: 100%;
    border-bottom: 1px solid;
}

section.resultsBlock.productOptionsContainer {
	header h2 {
		float: none;
	}
}

.productOptionsList {
	@extend %clear;
	list-style: none;
	padding: 10px;
	margin: 0;
	li {
		float: left;
		width: 50%;
	}
	@media only screen and (max-width: 600px){
		li {
			width: 100%;
		}
	}
}