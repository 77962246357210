//**
// Form Elements
//**

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  border-radius: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  border: none;
  //background: linear-gradient(#f7f7f7, #fff);
  transition: all 0.3s ease-out;
  box-shadow: inset 0 1px 4px rgba(0,0,0,0.4);
  margin-top: 10px;
  font-size: 16px;
  font-weight: 100;
  &:focus {
  //  border-color: $primary-color;
  }

  &.full-width {
    width: 100%;
  }
}

textarea {
 // height: $line-height*3;
}

form {
  //margin-bottom: $padding;

  // Wrap each form input/element in a field div
  .field {
   // margin-bottom: $padding/2;
  }

  // Notes go under the input fields
  .hint {
   // color: $subtle-color;
    margin-top: 5px;
  }

  // Required label
  .is-required {
    font-style: italic;
  }

  // Validation, add error class to the item div
  .has-error {

    // Validation reason
    .error-description {
    //  color: $danger-color;
      margin-top: 5px;
    }

    input[type=text],
    input[type=email],
    input[type=phone],
    input[type=password],
    input[type=number],
    input[type=search],
    textarea {
    //  border-color: $danger-color;
    }
  }
}


//form styles
.idea-submit-form,
legend.submit-idea, 
.request-submit-form, 
legend.request-idea{
  width: 75%;
  margin: 0 auto;

}

legend{
 font-size: 26px;
 padding-left: 0;

}


// .idea-submit-form{
//   legend{
//     padding-right: 20px;
//   }
//   form{
//     display: inline-block;
//   }
//   label{
//     margin-top: 10px;
//   }

//   .field{

//   }


// }

// legend.request-idea,
// legend.submit-idea{
//    margin-left: 7px;
// }



form{
  .btn-share,  
  .btn-request{
    margin-top: 40px;

  }
}


textarea {
  padding: 10px;
  height: 120px;
}


