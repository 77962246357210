.product-grid {
    margin: 0 -10px;
    p {
        padding: 0 10px;
    }
}

@media only screen and (min-width: 1901px) {
    // .has-margin .width-threeQuarters {
    // 	width: 74%;
    // }
    @include gridLayout(".product-grid", 5);
    .container {
        max-width: 1600px;
    }
}

@media only screen and (max-width: 1900px) and (min-width: 1201px) {
    @include gridLayout(".product-grid", 3);
}

@media only screen and (max-width: 1200px) and (min-width: 701px) {
    @include gridLayout(".product-grid", 3);
}

@media only screen and (max-width: 760px) and (min-width: 401px) {
    @include gridLayout(".product-grid", 2);
    .has-margin .width-threeQuarters {
        width: 100%;
    }
}

@media only screen and (max-width: 760px) {
    .btnGroup {
        width: 96% !important;
        margin: 10px 4% 10px 0 !important;
    }
}

@media only screen and (max-width: 400px) and (min-width: 100px) {
    // .product-grid{
    // 	width: 100%;
    // }
    @include gridLayout(".product-grid", 1);
}

//////////////////////////////////////HEADER
header {
    h2 {
        font-size: 2em;
        color: color(accentColor);
        margin-top: 0;
    }
    p {
        color: #596f73;
        font-size: 1em;
        
    }
}

.resultsContainer > header p {
    padding-right: 20px;
}

//////////////////////////////////////HELPER
.right {
    float: right;
    clear: both;
}

.left {
    float: left;
    clear: both;
}

.landing {
    background-color: #CCC;
}



.product-image-link {
    cursor: pointer;
}

//////////////////////////////////////ITEM
// GRIDS

.fullWidth {
    width: 100%;
}

.smallHeader {
    float: left;
    clear: both;
    width: 100%;
    color: #4788b9;
}

.mediumHeader {
    float: left;
    clear: both;
    width: 100%;
    color: #4788b9;
    font-size: 1.3em;
}

.projectSummary {
    margin: 0;
    padding: 0;
    list-style: none;
}

.projectSummary li.projectDetails-item {
    border-bottom: none;
    float: left;
    margin-left: 0;
    margin-right: 40px;
}

.myBorder {
    color: #e5e5e5;
    h3 {
        
    }
    .itemBorder {
        list-style: none;
        margin: 0;
        padding:0;
        li {
            font-size: 1em;
            margin: 0 0 10px;
            background-color: #2f404e;
            padding: 10px 20px;
            a {
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        @media (max-width: 500px){
            li {
                padding: 10px;
                line-height: 1em;
               font-size: 1em; 
               a span {
                display: none;
               }
            }
        }
    }
    .item, .spec {
        font-size: 1em;
    }
    .item {
        float: left;
        clear: left;
    }
    .remove {
        font-size: 14px;
        float: right;
        margin-top: 2px;
        text-decoration: none;
        text-transform: uppercase;
        color: inherit;
        img {
            margin-top: -3px;
        }
        &:hover {
            color: #fff;
        }
    }
}

.headerText {
    
}

#result_section_div section {
    margin: 20px 0 40px;
}

.resultsBtnGroup {
    margin-top: 39px;
    a.btn {
        float: none;
        width: 100%;
        padding: 4px 10px;
    }
    a.btn.add {
        float: none;

    }
}

.resultsBtnGroup.btnGroupFooter {
    a, a.btn.add {
        padding: 7px 10px;
        margin-top: 0;
        margin-bottom: 0;
    }
    a.btn.add {
        padding: 10px 10px 9px 10px;
    }
}


.product-grid.has-margin .grid-cell.productCell {
    overflow: hidden;
    position: relative;
}

.productCell {
    overflow: hidden;
    clear: none;
    padding: 0 10px 35px 10px;
    p {
        padding:0 !important;
    }

    .colorSelection {
        margin:0;
    }
}

.removeImage {
    padding-left: 22px;
    padding-top: 2px;
    padding-bottom: 20px;
    background: url('../images/remove_icon_cms.png') no-repeat top left;
    height: 19px;
    font-size: 12px;
}

.grid-cell img {
    width: 100%;
}

.has-margin .col.projectDetails-container {
    //
    width:23%;
    margin-left: 3%;
    aside h3 {
        clear: both;
        padding: 5% 5%;
        margin: 0;
        color: #4788b9;
        font-size: 1.5em;
        border-bottom: 1px solid #4e8abe;
    }
}

#main header.projectDetailsMainHeader {
    width: 100%;
}

input[type=text], input[type=email], input[type=phone], input[type=password], input[type=number], input[type=search], textarea{
    &.stuntedLength {
        width: 50%;
        min-width: 200px;
    }
}

.product-grid {
    .grid-cell {
        h3 {
            text-transform: uppercase;
            margin: 12px 0 4px 0;
            font-weight: 700;
        }
        p, label {
            margin-top: 6px;
            float: left;
            width: 100%;
            font-weight: 700;
        }
    }
}

.mainProdGrid {
    .grid-cell {
        h3 {
            min-height: 37px;
        }
    }
}

.asideBtnContainer {
    padding-bottom: 0;
    float: left;
    .btn {
        margin-top: 0;
    }
}

.detailsLabel {
    font-size: 1.688em;
    margin-bottom: 4px;
}

#project_details_form p.requiredFieldsText {
    font-size: 14px;
    margin: 30px 0 0 0;
}

.required {
    color: #ff0000;
    sup {
        font-size: 17px;
    }
}



.product-grid .grid-cell .colorSelection p {
    
}
.product-grid .grid-cell .colorSelection label {
    width: auto;
    float: left;
    margin-top: 19px;
    margin-bottom: 0;
}

.colorSelection {
    margin: 20px 0 0 0;
    select {
        clear: left;
        float: left;
        width: 100%;
        margin-top: 0;
        outline: 0;
        margin-bottom: 8px;
    }
}

.productList {
    h3 {
        color: #2F404E;
        font-weight: 700;
    }
    .colorSelection {
        margin: 0;
    }
}

.swatchContainer {
    img {
        width: 40px;
        height: 40px;
        float: right;
        margin-bottom: 10px;
    }
}

.projectDetails-item {
    border-bottom: 1px solid #c1c1c1;
    ul {
        margin: 0;
        padding: 4px 0;
        position: relative;
    }
    li {
        padding-left: 10px;
    }
    ul li {
        margin-left: 10px;
        padding: 2px 0;
        display: block;
        clear: left;
    }
    li.detailType {
        text-transform: uppercase;
        font-size: 1em;
        font-weight: 700;
        color: #5b7074;
        float: left;
    }
    li.detailName {
        margin-right: 10px;
        font-size: 12px;
        font-weight: 700;
        color: #594e49;
    }
}

a.prodDetailsLink {
    position: relative;
    width: 100%;
    display: block;
}

span.detailsText {
    position: absolute;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 700;
    bottom: 0;
    right: -114%;
    padding: 12px 10px;
    background-color: rgba(47, 64, 78, 0.8);
    color: #FFF;
    width: 100%;

    -webkit-transition: right 0.4s; /* Safari */
    transition: right 0.4s;
}

#project_details_form {
    p {
        margin: 0;
    }
}


#main > section.resultsContainer {
    > header{
        width: 100%;
        @media (max-width: 900px){
            .width-threeQuarters {
                width: 100%;
            }
            .width-quarter {
                width: 100%;
                max-width: 600px;
            }
        }
    }
}

#main > section.emailContainer {
    header h2 {
        text-transform: uppercase;
    }
    @media (max-width: 850px){
        > .half {
            width: 100%;
            margin-bottom: 40px;
        }

        .emailBtnGroup .half {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

#result_section_div header h2 {
    text-transform: uppercase;
}

.overageContainer {
    header {
        p {
            float: left;
            margin: 0;
        }
    }
    a.overageLink {
        color: #4a80ae;
        font-weight: 700;
        text-decoration: none;
        float: left;
        margin-left: 10px;
        margin-top: 4px;
        font-size: 12px;
        @media (max-width: 550px){
            clear: left;
        }
        .btn_arrow {
            margin-top: -2px;
            margin-right: 5px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}

.productOverviewList {
    margin: 0;
    padding: 0;
    list-style: none;
    li:last-child {
        ul {
            border-bottom: none;
        }
    }
}

.productOverview.product-grid, .productListing.product-grid {
    clear: both;
    border-top: 1px solid #c1c1c1;
    padding: 0 0 20px 0;
    margin-top: 0;
    list-style: none;
    .grid-cell {
        margin-left: 10px;
        margin-top: 20px;
    }
    .productSide {
        margin-top: 0;
    }
}

.piecesContainer {
    font-size: 1.3em;
    width: 100%;
    margin-top: 30px;
    border-bottom: 1px solid #c1c1c1;
}

.productOverviewList .productListing.product-grid {
    padding: 0 0 18px 0;
}

.productOverview.product-grid {
    border-top: none;
    border-bottom: none;
}

.infoBlock {
    font-size: 11px;
    padding: 0 10px;
    display: none;
}

.infoBlock.active {
    display: block;
}

.infoButton {
    position: absolute;
    top: 2px;
    right: 0;
}

.pdfProjectText {
    font-weight: 700;
    color: #5b7074;
    span {
        color: #594e49;
    }
}

.emailBtnGroup {
    margin: 20px 0;
    width: 100%;
    a {
        width: 100%;
        margin: 0;
    }
}

.patternPieceTitle {
    float: none !important;
    padding-left: 0 !important;
}

.productDetailsLink {
    color: #5b7074;
    text-decoration: none;
    img {
        width: 21px;
        height: 20px;
        margin-top: -3px;
        margin-right: 8px;
    }
    &:hover {
        text-decoration: underline;
    }
    @media print {
        display: none;
    }
}

@media only screen and (max-width: 760px){
    .qtyCell {
        clear: left !important;
        float: left;
        max-width: 250px;
        width: 64% !important;
    }

    .sqFtCell {
        float: left;
        width: 28% !important;
        clear: none !important;
    }

    .commonCell {
        width: 40% !important;
        float: left !important;
    }
}

.productListing  {
    border-top: 1px solid #c1c1c1;
    border-bottom: none;
}

.productOverviewList {
    li {
        &:first-of-type {
            .productListing  {
                border-top: none;
            }
        }
    }
}

.detailsText.active {
    right: 0%;
}

.prodDetailsContainer {
    text-align: right;
}

.productList {
    @media (max-width: 550px){
        .col {
            clear: left;
            margin-left: 0;
            margin-bottom: 3px;
            width: 90%;
        }
        .removeContainer {
            position: absolute;
            width: auto !important;
            text-align: right !important;
            float: none;
            right: 14px;
        }
    }

    @media (max-width: 320px){
        .removeContainer {
            top: 4px;
        }
    }
}

.prodDetailsLink {
    img {
        width: 21px !important;
        height: 20px;
        position: absolute;
        bottom: 11px;
        right: 8px;
    }
}

.selectionContainer {
    position: relative;
}

.inputDescription {
    font-size: 0.9em;
    padding: 10px;
}

.removeContainer {
    float: right;
}

.projTypeGroup {
    padding: 0;
    margin: 0;
    list-style: none;
    clear: both;
    li {
        clear: left;
        float: left;
        font-size: 1.2em;
        margin-right: 30px;
        margin-top: 10px;
        label {
            cursor: pointer;
        }
        > * {
            float: left;
        }

        /* Toggled State */
        input[type=radio]:checked ~ div.pseudoRadioBtn {
           background-position: 0 -24px;
        }
    }
}

.pseudoRadioBtn.active {
    background-position: 0 -24px;
}

.resultsBlock {
    font-weight: 700;
    margin-bottom: 10px;
    h3 {
        font-weight: 700;
    }
    a {
        font-weight: 400;
    }
}

.pseudoRadioBtn {
    width: 24px;
    height: 24px;
    background-image: url('../images/radio_btn_image.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    margin-right: 10px;
}

.inches {
    font-size: 0.8em;
}

.radioBtn {
    display: none;
    position: absolute;
}

//////////////////////////////////////MEDIA QUERIES
//////////////////////////////////////
//////////////////////////////////////
@media only screen and (max-width: 900px) {
    .projectDetails-item li.detailName {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 760px) {
    .col.width-quarter.projectDetails-container {
        margin-left: 0;
        width: 100%;
    }
    .projectDetails-container aside h3 {
        padding-left: 10px;
    }

    .prodDetailsContainer {
        text-align: left;
    }
}

.productSelect {
    img {
        float: left;
        clear: both;
    }
}

//////////////////////////////////////  MEDIA UNDER list build
.productSide {
    margin-top: 20px;
    .media-content {
        h3 {
            text-transform: uppercase;
            color: #5b7074;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 1em;
        }
        p {
            margin: 10px 0 0 0;
            font-size: 1em;
            font-weight: 100;
            padding: 0;
        }
    }

    .colorSwatch {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


.prodPieces {
    margin-top: 20px;
    p {
        margin: 0 0 10px 0;
    }
}

.prodResults {
    background-color: #666;
    padding: 0 10px;
    margin-top: 10px;
}

.half {
    width: 48%;
    display: block;
    float: left;
}

.half:nth-of-type(2) {
    float: right;
}

//////////////////////////////////////BUTTONS
//Spec
header a.btn {
    float: right;
    margin-top: -44px;
    margin-right: 18px;
}

a.btn.add {
    width: 100%;
    border: 2px solid color(buttonBgColour);
    background-color: color(buttonBgColour);
    background-image: none;
    color: #fff;
    margin-top: 0;
    padding: 10px 13px 10px 0px;
    & ~ .arrow {
        background-position: 0 -14px;
    }
    &:hover {
        border: 2px solid color(buttonBgColourRollover);
        background-color: color(buttonBgColourRollover);
    }
}

.addToProjectContainer {
    clear: both;
    .arrow {
        width: 29px;
        height: 14px;
        display: block;
        margin: 0 auto;     
    }

    a img {
        width: 21px;
        height: 21px;
    }

    a {
        &:hover {
            background-color: #3BA4FF;
            border: 2px solid #3BA4FF;
        }
    }
}

//global
a.btn {
    text-decoration: none;
    padding-right: 24px;
    display: inline-block !important;
}

.btn, input[type="button"].btn, input[type="submit"].btn {
    border: 2px solid #ffcf3e;
    cursor: pointer;
    padding: 14px 50px 14px 30px;
    background-color: #ffcf3e;
    background-repeat: no-repeat;
    background-position: top 13px right 28px;
    display: inline-block;
    margin: 10px 0;
    color: #2b373a;
    text-align: center;
    font-weight: bold;
    font-size: 0.9em;
    text-transform: uppercase;
    .btn_arrow {
        margin-top: -3px;
        margin-left: 6px;
        margin-right: 6px;
    }
    &:hover {
        background-color: #FFC000;
        border: 2px solid #FFC000;
        color: #2b373a !important;
    }
}

.btn .btn_arrow, input[type="button"].btn .btn_arrow, input[type="submit"].btn .btn_arrow {
    margin-top: -3px;
    margin-left: 6px;
    margin-right: 6px;
}

.btn:hover, input[type="button"].btn:hover, input[type="submit"].btn:hover {
    background-color: #FFC000;
    border: 2px solid #FFC000;
}

.btnGroup {
    width: 92%;
    margin: 0 8% 15px 0;
    .btn {
        width: 100%;
        max-width: 223px;
    }
}


.btnBack, .btnNext {
    min-width: 200px;
}


a.btn.btnBack {
    background-color: transparent !important;
    border: 2px solid #ffcf3e;
    &:hover {
        background-color: #FFC000 !important;
        border: 2px solid #FFC000 !important;
    }
}

input[type="button"].btn, input[type="submit"].btn {
    background-image: url('../images/btn_arrow.png');
    background-repeat: no-repeat;
}

a.btn.dark {
    background-color: #ffcf3e;
    color: #2b373a;
}

.buttonResults {
    position: relative;
    margin: 0;
    a.btn {
        margin: 0 0 10px 0;
    }
    a.btn.find {
        clear: both;
    }
    a.btn.print {
        margin-left: 90px;
    }
}

.prodResults .btn {
    margin-top: 20px;
    float: right;
    margin-right: 10px;
}

//////////////////////////////////////EDIT STATE

.editState{
	margin-bottom: 20px;
	background-color: #CCC;
	padding:10px 0;
	
	&:hover{
		background-color:#CCC;
	}


	&:active{
		background-color: #CCC;
	}	

	a.btn{
		background-color: #666;
	}


}


a.default{
	display: inline-block;
	margin: 20px 0;
	padding-left: 20px;
	text-decoration: none;
	color: #000;
	
}

.editTitle input[type=text]{
    margin: 10px 0 0 10px;
    padding-left: 10px;
}



// .editState, .addState {
//     padding: 0;
//     margin-bottom: 20px;
//     background-color: #CCC;
//     .editTitle {
//     	padding: 0 10px;
//         .btn {
//             background-color: #999;
//         }
//     }
//     .editTitle input {
//         margin-left: 10px;
//     }

// }

// .defaultState {
//     padding: 0;
//     background-color: #FFF;
// }

// .editCurrent {
//     background-color: #EEE;
// }

// a.default, .editTitle a {
//     margin-top: 20px;
//     margin-left: 10px;
//     display: block;
//     color: #000;
//     text-decoration: none;
// }


// .editTitle input{
// 	margin-top: 10px;
// 	margin-left: 10px;
// }



@media only screen and (max-width: 401px) {
    .has-margin .width-threeQuarters {
        width: 100%;
    }
}

@media only screen and (max-width: 375px){

    .colorSelection  {
        margin: 0;
    }

    .has-margin .col.projectDetails-container aside h3 {
        padding: 10px 0;
    }

    .projectDetails-item ul li {
        margin-left: 0;
    }

    .infoButton {
        top: 7px;
    }

    .btnGroup.myBorder {
        margin-bottom: 30px !important;
    }

    .resultsBtnGroup {
        margin-bottom: 30px;
    }

    .btnGroupFooter {
        .half {
            width: 100%;
            margin-top: 10px;
        }
    }

    .makeUpdatesContainer {
        .col {
            width: 100%;
            margin-left: 0;
        }
    }

    .productSide {
        .media-content {
            p {
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 400px){
    .productCell {
        padding: 0 10px 65px;
    }
}