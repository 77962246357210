// Shortcut for mapped colors in _variables.scss

@function color($key) {

  @if map-has-key($color, $key) {

    @return map-get($color, $key);
  }

  @warn "Unknown color `#{$key}` in $color.";
  @return null;
}


// Shortcut for mapped font stacks in _variables.scss

@function fontStack($key) {

  @if map-has-key($fontStack, $key) {

    @return unquote(map-get($fontStack, $key));
  }

  @warn "Unknown stack of `#{$key}` in $fontStack.";
  @return null;
}



// Shortcut for mapped font-size in _variables.scss

@function fontSize($key) {

  @if map-has-key($fontSize, $key) {

    @return map-get($fontSize, $key);
  }

  @warn "Unknown size of `#{$key}` in $fontSize.";
  @return null;
}



// Shortcut for mapped layout in _variables.scss

@function siteLayout($key) {

  @if map-has-key($siteLayout, $key) {

    @return map-get($siteLayout, $key);
  }

  @warn "Unknown property of `#{$key}` in $siteLayout.";
  @return null;
}



// Shortcut for mapped z-index value in _variables.scss

@function zIndex($key) {

  @if map-has-key($zIndex, $key) {

    @return map-get($zIndex, $key);
  }

  @warn "Unknown property of `#{$key}` in $zIndex.";
  @return null;
}
