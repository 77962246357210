

.bread-crumb {
	h2{
		font-size:24px;
	}
	
	ol{
		margin: 0;
		padding:0;
	}
	ol li {
		float: left;
		margin-left: 40px;
	}
}

.detailsLabel {
	p {
		font-size: 0.6em;
	}
}

.wasteOverageContainer {
	input[type="text"] {
		width: 48%;
		@media (max-width: 570px){
			width: 100%;
		}
	}
}

.calcTypeLabel {
	float: left;
	text-transform: uppercase;
	font-size: 1em;
	color: #fff;
}

.calcTypeSelect {
	> div {
		padding: 20px;
		background-color: #fff;
		text-align: center;
		width: 48%;
		float: left;
		line-height: 1.7em;
	}
	.cell1 {
		margin-right: 2%;
	}
	.cell2 {
		margin-left: 2%;
	}
	h3 {
		text-transform: uppercase;
		color: color(accentColor);
		font-size: 1.6em;
	}
	@media (max-width: 600px){
		.cell1, .cell2 {
			width: 100%;
			margin: 0;
		}
		.cell2 {
			margin: 30px 0 0 0;
		}
	}
}

.seeAll {
	font-size: 11px;
	float: right;
	color: #fff;
}




#main .detailsContainer, #main .productContainer {
	background-color: color(siteBackground);
	padding-bottom: 50px;
	padding-top: 20px;
	margin-bottom: 0;
	margin-top: 0;
	header {
		width: 100%;

		p {
			//font-size: 1em;
			//line-height: 1.8em;
			padding-right: 20px;
		}
	}
}

#main .detailsContainer, #main .detailsContainer.cmsLoginContainer, #main .borderContainer  {
	header {
		width: 100%;
	}
}

.resultsContainer {
	header {
		width: 100%;
	}
}

section.resultsBlock {
	margin-top: 80px;
	header {
		border-bottom: 1px solid color(accentColor2);
		margin-bottom: 15px;
		height: 31px;
		h2 {
			float: left;
			text-transform: none;
			font-size: 1.6em;
			padding: 0 0 5px;
			margin-bottom: 0;
			@media only screen and (max-width: 600px){
				font-size: 1em;
			}
		}
	}
	&.selections {
		label {
			clear: left;
			margin: 10px;
			input {
				position: relative;
				top: 0px;
			}
		}
	}
	&.selectColorsBlock {
		margin-top: 30px;
	}
}

.singleDetailsLink {
	float: right;
}