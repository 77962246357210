// Locally stored web-font

@mixin localWebFont($fontName, $fileName) {

  @font-face {

    font-family: $fontName;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/" + $fileName + ".eot");
    src: url("../fonts/" + $fileName + ".eot?#iefix") format("embedded-opentype"),
         url("../fonts/" + $fileName + ".woff") format("woff"),
         url("../fonts/" + $fileName + ".ttf") format("truetype"),
         url("../fonts/" + $fileName + ".svg#" + $fileName + "") format("svg");
  };
}



// Grid layout

@mixin gridLayout($parentSelector, $rowCount) {

  // Calculate col width accounting for margin in _variables.scss
  $margin-width: (100% - siteLayout(margin) * ($rowCount - 1)) / $rowCount;

  #{$parentSelector} .grid-cell {
    width: 100% / $rowCount;

    /*
     * NOTE: The grid styles depend on the nth-child selector and requires
     * Selectivizr.js to function correctly in IE8.
     */

    // First row
    &:nth-child(-n+#{$rowCount}) { margin-top: 0; }

    // New row
    &:nth-child(#{$rowCount}n+1) {
      clear: left;
      margin-left: 0;
    }
  }

  #{$parentSelector}.has-margin .grid-cell { width: $margin-width; }
}
