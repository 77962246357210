/* --------------------------------------------------------------------------- */
/* PRINT STYLES */
/* --------------------------------------------------------------------------- */

@media print {

  // Black prints faster

  *,
  *:before,
  *:after {
    background: transparent !important;
    border-color: #000 !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  // Add underline to <a>

  a,
  a:visited { text-decoration: underline; }

  // Display the href in brackets after <a>

  a[href]:after { content: "("attr(href)")"; }

  // Don't display undesirable href values

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after { content: ""; }

  // Display the title in brackets after <abbr>

  abbr[title]:after { content: "("attr(title)")"; }

  // Print <thead> at the top of the page if there is a page break in the table

  thead { display: table-header-group; }

  // Avoid orphans and widows

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  // Avoid undesirable page breaks

  pre,
  blockquote,
  tr,
  img { page-break-inside: avoid; }

  h2,
  h3 { page-break-after: avoid; }

  .resultsBtnGroup, .borderContainer > header, .seeAllCalc, .breadCrumb{display:none;}
}


@media (min-width:761px) and (max-width:810px)
{
  .new-width
  {
    width: 25% !important;
margin-left: 1% !important;
  }
  
}
@media (max-width:500px)
{
  #login_form .col.width-half 
  {
    width: 100% !important;
  }
  .col.width-fifth {
    margin-right: 20px;
    margin-top: 10px !important;
}
  .percentage-width-fifth
  {
    width:100% !important;
  }
}

@media (max-width: 460px){
  .btnGroup {
    margin: 0 !important;
    width: 100% !important;
  }
  .btnBack, .btnNext, .btnSkip {
    max-width: 100% !important;
    margin: 0 0 20px 0 !important;
  }
}

@media (max-width:410px)
{
  .editSectionHeader .btn.addNew
  {
  text-align: center;
  float: right;
  }
  
  .col.width-fifth {
    margin-right: 20px !important;
    margin-top: 10px;
}
}
@media (max-width:320px)
{
  
  .editInstructionList li
  {
    width:100%;
  }
  .itemBorder li .col 
  {
    width:100% !important;
    margin-top:10px;
    text-align:center;
    padding:0px !important;
    margin-left:0px !important;
  }
  .itemBorder li .remove 
  {
      float: none;
    margin: 0px auto;
  }
  
  .resultsBtnGroup.clear.col.width-quarter 
  {
    padding-left: 0px;
    margin-left: 0px !important;
  }
}