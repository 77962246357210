/* --------------------------------------------------------------------------- */
/* BASIC LAYOUT */
/* --------------------------------------------------------------------------- */

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: siteLayout(width);
  position: relative;

  @extend %clear;
}

// Basic widths
// Use in conjunction with .col and .table-cell
.width-half { width: 50%; }
.width-third { width: 33.333%; }
.width-twoThirds { width: 66.666%; }
.width-quarter { width: 25%; }
.width-threeQuarters { width: 75%; }
.width-fifth { width: 20%; }
.width-twoFifths { width: 40%; }
.width-threeFifths { width: 60%; }
.width-sixth { width: 16.66% }
.width-seventh { width: 14.28% }

// Calculate col width accounting for margin in _variables.scsss
$half-margin: (100% - siteLayout(margin)) / 2;
$third-margin: (100% - siteLayout(margin) * 2) / 3;
$quarter-margin: (100% - siteLayout(margin) * 3) / 4;
$fifth-margin: (100% - siteLayout(margin) * 4) / 5;

.has-margin {

  .width-half { width: $half-margin; }
  .width-third { width: $third-margin; }
  .width-twoThirds { width: ($third-margin * 2) + siteLayout(margin); }
  .width-quarter { width: $quarter-margin; }
  .width-threeQuarters { width: ($quarter-margin * 3) + (siteLayout(margin) * 2); }
  .width-fifth { width: $fifth-margin; }
  .width-twoFifths { width: ($fifth-margin * 2) + siteLayout(margin); }
  .width-threeFifths { width: ($fifth-margin * 3) + (siteLayout(margin) * 2); }
}

.row { @extend %clear; clear: both; padding: 0 0 25px; }

  // Control width with the basic width classes above
  .col { float: left; }
  
  .has-margin .col {
    &:first-child { margin-left: 0; }
    &.width-half {
      &:nth-child(odd) {
        margin-left: 0;
      }
    }
  }

#project_details_form, #login_form {
  .has-margin .col {
    margin-left: 4%;
    &:first-child { margin-left: 0; }
    &.width-half {
      &:nth-child(odd) {
        margin-left: 0;
      }
    }
  }
}

/*
 * NOTE: There is a bug in iOS that prevents the recalculation of layout
 * if an orientation change results in a media query being applied that
 * changes the display from table to block.
 */

.table {
  border-collapse: collapse;
  display: table;
  table-layout: fixed;
  width: 100%;
}

  // Control width with the basic width classes above
  .table .table-cell {
    display: table-cell;
    margin: 0;
  }

/*
* NOTE: The grid styles depend on the nth-of-type selector and requires
* Selectivizr.js to function correctly in IE8
*/

.grid { @extend %clear; }

  .grid-cell { float: left; }

  // Rows of two
  .grid-two .grid-cell {
    width: 50%;

    // First row
    &:nth-child(-n+2) { margin-top: 0; }

    // New row
    &:nth-child(2n+1) {
      clear: left;
      margin-top: 0;
    }
  }

  .grid-two.has-margin .grid-cell { width: $half-margin; }

  // Rows of three
  .grid-three .grid-cell {
    width: 33.333%;

    // First row
    &:nth-child(-n+3) { margin-top: 0; }

    // New row
    &:nth-child(3n+1) {
      clear: left;
      margin-left: 0;
    }
  }

  .grid-three.has-margin .grid-cell { width: $third-margin; }

  // Rows of four
  .grid-four .grid-cell {
    width: 25%;

    // First row
    &:nth-child(-n+4) { margin-top: 0; }

    // New row
    &:nth-child(4n+1) {
      clear: left;
      margin-left: 0;
    }
  }

  .grid-four.has-margin .grid-cell { width: $quarter-margin; }

  // Rows of five
  .grid-five .grid-cell {
    width: 20%;

    // First row
    &:nth-child(-n+5) { margin-top: 0; }

    // New row
    &:nth-child(5n+1) {
      clear: left;
      margin-left: 0;
    }
  }

  .grid-five.has-margin .grid-cell { width: $fifth-margin; }

.media { @extend %clear; }

  // Sane default to prevent fixed content from overtaking fluid
  .media-left,
  .media-right { max-width: 33.333%; }

  .media-left { float: left; }
  .media-right { float: right; }

  .has-margin {

    .media-left { margin-right: siteLayout(margin); }
    .media-right { margin-left: siteLayout(margin); }
  }

  .media-content { overflow: hidden; }
