// Image replacement

%ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.ir { @extend %ir; }

// Sprite images

%sprite {
  background: url("/images/sprite.png") no-repeat;
  display: inline-block;

  @extend %ir;
}

.sprite { @extend %sprite; }

// Clear fix for containers with floated children

%clear {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear { @extend %clear; }

// Hide from view and screen readers

%hidden { display: none !important; }

.hidden { @extend %hidden; }

// Hide from view but make available to screen readers

%visuallyHidden {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyHidden { @extend %visuallyHidden; }


// <style>.embed-container 
// { 
// position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%;
//  } 
// .embed-container iframe, 
// .embed-container object, 
// .embed-container embed { 
// position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
// }</style>

// <div class='embed-container'>
// <iframe src='http://www.youtube.com/embed/QILiHiTD3uc' frameborder='0' allowfullscreen></iframe>
// </div>