/* --------------------------------------------------------------------------- */
/* HTML ELEMENTS */
/* --------------------------------------------------------------------------- */

html {
  background-color: color(siteBackground);
  color: color(text);
  font-family: fontStack(default);
  font-size: fontSize(normal);
  line-height: siteLayout(lineHeight);
}

html, body {
  height: 100%;
}

h2 {
  font-size: 1.6em;
}

::-moz-selection { background-color: #b3d4fc; }
::selection { background-color: #b3d4fc; }

// 1. Reset background-color for active links in IE 10
// 2. Improve readability when focused and hovered

a {
  background-color: transparent; // 1
  color: #4a80ae;
  &:active,
  &:hover { outline: 0; } // 2
}

hr { border-top: 1px solid #CCC; }

section {
  outline: 0;
}

label {
  color: color(labelColour);
}

/* --------------------------------------------------------------------------- */
/* GENERAL STYLES */
/* --------------------------------------------------------------------------- */

// Skip to content links for accessibility

.skipLink {
  background-color: #000;
  background-color: rgba(#000, 0.6);
  color: #FFF;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    text-align: center;
    top: 0;
    position: absolute;
    width: 100%;
  }

  @extend %visuallyHidden;
}


// Remove outline when focused via .skipLink

.sidebar:focus,
.main:focus { outline: none; }

.sidebar h3 {
  margin-bottom: 0;
}

.has-margin .col.typeContainer {
  &:nth-child(odd) {
    margin-left: 0;
    clear: left;
  }
}

// Rely on classes for styling headings
// Heading elements <h1> through <h6> have been reset in _baseline.scss

.heading {
  font-family: fontStack(default);
  font-weight: bold;

  &:first-child { margin-top: 0; }

  a {
    color: inherit;
    text-decoration: none;

    &:hover { text-decoration: underline; }
  }
}

// Loop through $fontSize map and create classes for font-size

@each $name, $value in $fontSize {

  .fontSize-#{$name} { font-size: $value !important; }
}

// Loop through $color map and create classes for color

@each $name, $value in $color {

  // Ignore default colors
  @if $name != "text" and $name != "form" and $name != "selection" {

    .color-#{$name} { color: $value !important; }
  }
}

////////////////////////////////////// BREADCRUMB //////////////////////////////////////
.breadCrumb {
    display: inline-block;
    padding-left: 0px;
    padding-bottom: 10px;
    margin-left: 20px;
    list-style: none;
    border-bottom: 1px solid color(accentColor2);
    li {
        float: left;
        margin-top: 5px;
        margin-right: 10px;
        list-style-position: outside;
        height: 30px;
        line-height: 30px;
        background-color: color(breadCrumbBase);
        font-weight: 700;
        font-size: 0.75em;
        text-transform: uppercase;

        a {
            padding: 8px 7px 8px 0;
            text-decoration: none;
            color: #2b373a;
        }
        p {
            padding: 0px 10px;
            display: inline-block;
            margin: 0;
            color: #fff;
        }
        span.number {
            display: inline-block;
            width: 30px;
            text-align: center;
            height: 100%;
            padding: 0 10px;
            color: #fff;
            background-color: color(breadCrumbNumber);
        }
        &.goBack {
            color: #e4e4e4;
            background-color: color(breadCrumbBaseGoBack);
            .number {
              background-color: color(breadCrumbGoBackNumber);
            }
            a {
                color: #e4e4e4;
                &:hover {
                  color: #fff;
                }
            }
        }
        &.current {
            font-weight: bold;
            background-color: #fff;
            p {
               color: #2b373a; 
            }
            span.number {
              background-color: color(breadCrumbCurrentNumber);
            }
        }
        &.lastItem {
            margin-right: 0;
        }
    }
    .login-error-msg{
        .breadCrumb .introduction-div {     
          font-size:16px;
      }
    }
    
    @media (max-width: 720px){
      li {
        font-size: 0.55em;
          a {
          padding: 0;
          
        }
      }

      li span.number {
        margin-right: 0;
      }
      
    }

    @media (max-width: 635px){
      li {
        p {
          display: none;
        }
      }
    }
}

.product-grid .grid-cell p.borderDesc {
  font-weight: 300;
  font-size: 11px;
  margin-top: 0;
}

.seeAllCalc {
  margin: 0 22px;
}

.logoutLink.right {
    padding: 10px 20px 0 0;
    width: 75%;
    text-align: right;
    background-color: color(siteBackground);
    @media (max-width: 860px){
      width: 100%;
      padding: 10px 20px;
    }
}

#main > section {
  padding-left: siteLayout(padding);
  padding-right: siteLayout(padding);
  margin-bottom: 30px;
  > header {
    width: 100%;
    float: left;
  }
  .introImage {
    display: block;
    float: left;
    margin: 0 0 30px;
    height: auto;
    width: auto;
  }
}

#main > section.calcType {
  > header {
    width: 100%;
    p {
      text-align: center;
      color: #596f73;
      font-size: 1.5em;
      width:90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.desc {
  margin: 0;
  font-size: 12px;
}

.waste-overage-div, .waste-overage-div-next {
  a.updateBtn {
    color: #fff;
    padding: 5px 10px;
    margin: 16px 0px 0px 10px;
    background-color: #4E8ABE;
    float: left;
    font-size: 12px;
    &:hover 
    {
      background-color: #3BA4FF;
    }
  }
}

#waste_overage_input {
  width: 100px;
  float: left;
  clear: left;
}

.detailsLinkContainer .media-content{
  text-align: right;
}

.message-container {
    padding: 14px 20px;
    text-align: center;
    width: 100%;
    background-color: #ffcf3e;
    font-weight: 700;
    color: #2B373A;
    position: fixed;
    top: 0;
    z-index: 2000;
    /* display: none; */
    outline: 0;
}

.error-msg {
  margin-top: 8px;
  display: block;
  color: #E41717;
  line-height: 1.1em;
  font-size: 14px;
  clear: both;
}

.headerImage {
  margin: 10px 0 40px 0;
}

.importantMessage {
  h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  p {
    margin: 10px 0;
    font-size: 13px;
  }
}

.paversDetailsLink {
  clear: both;
  float: left;
  margin-top: 10px;
}

.colorSwatch {
  @extend %clear;
  margin-top: 10px;
  img {
    width: 40px !important;
    height: 40px;
    float: left;
    margin-right: 7px;
  }
  p, h3 {
    margin: 0;
    padding: 0;
    float: none !important;
  }
}

@media (max-width: 810px){
  #main > section > header, #main > section > header.projectDetailsMainHeader {
    width: 100% !important;
  }

  #main > section .introImage {
    float: left;
    clear: left;
    width: auto;
  }

  .detailsContainer {
    .row {
      padding: 0;
    }
    .has-margin .width-half {
      margin: 20px 0;
      width: 100%;
      margin-left: 0 !important;
    }
    .has-margin .col {
      //margin-left: 0;
    }
  }


}




